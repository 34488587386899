import React from "react"
import _ from "lodash"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Content from "../components/content"
import Sidebar from "../components/sidebar"
import { SectionCopy, SectionTitle } from "../components/content/styles"

class IndexPage extends React.Component {
  render(){
    return (
      <Layout page="non">
        <SEO title="Kartos Boreas" />
        <div css={{"margin": "6em 0"}}>
          <SectionTitle className="small">
            Sorry, this website is not currently available in your country.
          </SectionTitle>
          <SectionCopy>
            Please try again later.
          </SectionCopy>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
